import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const dev: IPartnerAppSettings = {
  flightingAppEnvironment: 'Dev',
  esxpBaseUrl: 'http://localhost:5000/',
  // cdnBaseUrl: 'https://gcmra-ui-uat-react.azureedge.net',
  cdnBaseUrl: 'http://localhost:4567',
  serviceEndPoints: [
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.trafficmanager.net',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/lmt-coreapi-dev',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://servicesuat.microsoft.com/rmavl',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://grmapiappsit.azurewebsites.net',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://esxp-sit.microsoft.com',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'http://localhost:7071',
    },
  ],
  AvailabilityBaseUrl: 'https://servicesuat.microsoft.com/rmavl',
};
