import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const sit: IPartnerAppSettings = {
  flightingAppEnvironment: 'SIT',
  esxpBaseUrl: 'https://servicesint.azurewebsites.net/',
  cdnBaseUrl: 'https://gcmra-ui-uat-react-bwdmdaevc6aydfgq.b02.azurefd.net',
  serviceEndPoints: [
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.trafficmanager.net',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/lmt-coreapi-dev',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://esxp-sit.microsoft.com/rmavl',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://grmapiappsit.azurewebsites.net',
    },
    {
      clientId: '38578c27-d821-45d3-95db-fcf0e8371e68',
      serviceEndPoint: 'https://esxp-sit.microsoft.com',
    },
  ],
  AvailabilityBaseUrl: 'https://esxp-sit.microsoft.com/rmavl',
};
