import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const prod: IPartnerAppSettings = {
  flightingAppEnvironment: 'Prod',
  esxpBaseUrl: 'https://esxp.microsoft.com/',
  cdnBaseUrl: 'https://gcmra-ui-prod-react.azureedge.net',
  serviceEndPoints: [
    {
      clientId: '6db0b12a-edd4-4521-8406-a615ce8659d6',
      serviceEndPoint: 'https://oneprofileprodapipack.azurefd.net',
    },
    {
      clientId: '22c68362-0f39-4ef6-a5a6-2eb389b8592f',
      serviceEndPoint: 'https://professionalservices.microsoft.com/lmt-coreapi',
    },
    {
      clientId: 'bc72c2f2-9689-42a0-aa49-f579509c867b',
      serviceEndPoint: 'https://esxp.microsoft.com/rmavl',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://esxp-prod.azurefd.net/grm/rm',
    },
    {
      clientId: '72a9e05a-2c3c-47ae-bbb2-06ec753f307a',
      serviceEndPoint: 'https://esxp.microsoft.com/',
    },
    {
      clientId: 'bc72c2f2-9689-42a0-aa49-f579509c867b',
      serviceEndPoint: 'https://emailnotifications-prod-wus.azurewebsites.net',
    },
  ],
  AvailabilityBaseUrl: 'https://esxp.microsoft.com/rmavl',
};
