import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const aurorasit: IPartnerAppSettings = {
  flightingAppEnvironment: 'AuroraSIT',
  esxpBaseUrl: 'https://esxp-aurora-sit.microsoft.com/',
  cdnBaseUrl: 'https://gcmra-ui-uat-react-bwdmdaevc6aydfgq.b02.azurefd.net',
  serviceEndPoints: [
    {
      clientId: '',
      serviceEndPoint: '',
    },
  ],
  AvailabilityBaseUrl: 'https://esxp-aurora-sit.microsoft.com/rmavl',
};
