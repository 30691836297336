import { IPartnerAppSettings } from '../interface/IPartnerAppSettings';

export const uat: IPartnerAppSettings = {
  flightingAppEnvironment: 'UAT',
  esxpBaseUrl: 'https://servicesuat.microsoft.com/',
  cdnBaseUrl: 'https://gcmra-ui-uat-react-bwdmdaevc6aydfgq.b02.azurefd.net',
  serviceEndPoints: [
    {
      clientId: '99b7fa0a-a125-4f7a-abea-efb429bb8972',
      serviceEndPoint: 'https://oneprofileuatapipack.trafficmanager.net',
    },
    {
      clientId: '5eb170f9-f828-4121-936c-288eb43b050e',
      serviceEndPoint:
        'https://professionalservicesint.microsoft.com/time-core',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://servicesuat.microsoft.com/rmavl',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://esxp-uat.azurefd.net/grm/rm',
    },
    {
      clientId: '778329d6-a980-4f3e-bf02-7bc44a384e15',
      serviceEndPoint: 'https://servicesuat.microsoft.com',
    },
    {
      clientId: '6f40053e-5319-40e5-a90b-6f714506d96d',
      serviceEndPoint: 'https://emailnotifications-uat-wus.azurewebsites.net',
    },
  ],
  AvailabilityBaseUrl: 'https://servicesuat.microsoft.com/rmavl',
};
